import React from "react";
import { Row, Col } from "react-bootstrap";
import "./from.style.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function SignForm(props) {
  return (
    <from className="w-100">
      <Formik
        initialValues={{
          ...initialState,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          props.onNext(values.no_pelanggan);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          isValid,
        }) => (
          <Form>
            <Row>
              <Col xs={12}>
                <div className="my-1">
                  <label className="label-basic mb-3">No. Pelanggan</label>
                  <input
                    className="input-basic"
                    type="text"
                    placeholder="Masukkan nomor perlanggan"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="no_pelanggan"
                    id="no_pelanggan"
                  />
                  {touched.no_pelanggan && errors.no_pelanggan && (
                    <p
                      className="error"
                      style={{ color: "red", marginTop: "10px" }}
                    >
                      {errors.no_pelanggan}
                    </p>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn-basic"
                    type="submit"
                    disabled={!isValid || props.loading}
                  >
                    {props.loading ? "Please Wait.." : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </from>
  );
}

const validationSchema = Yup.object().shape({
  no_pelanggan: Yup.string().required("Nomor perlanggan dibutuhkan"),
});
const initialState = {
  no_pelanggan: "",
};
