import React from "react";
import "./iframe.style.scss";
import aktivasi from "assets/icons/aktivasi.svg";

export default function ModalIfrmae(props) {
  return (
    <div className="backdrop">
      <div className="iframe">
        <div className="header d-flex justify-content-between p-2 align-items-center">
          <div className="icon">
            <img src={aktivasi} alt="icon" />
          </div>
          <div className="title">
            <span>{props.title}</span>
          </div>
          <div className="back-menu" onClick={props?.backMenu}>
          Keluar ke menu utama
        </div>
        </div>
        <iframe src={props.url} title="sobat bisnis" />
      </div>
    </div>
  );
}
