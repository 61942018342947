import actionType from "./types";
// import { getLocalStore } from "next-persist";

const defaultState = {
  isLogin: false,
  user: null,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionType.GET: {
      return {
        ...state,
      };
    }
    case actionType.SET: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case actionType.UPDATE: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case actionType.DELETE: {
      return {
        isLogin: false,
        user: null,
      };
    }
    // DEFAULT
    default: {
      return state;
    }
  }
};

export default reducer;
