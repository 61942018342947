import React from "react";
import { Icon } from "@iconify/react";
import "./konsultasi.style.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col, Button } from "react-bootstrap";
import backArrow from "assets/icons/backarrow.svg";
import * as serviceNik from "service/checknik";

export default function Index() {
  const [step, setStep] = React.useState(null); //null, army, umkm
  return (
    <>
      {step === null && (
        <div className="konsultasi d-flex justify-content-around">
          <div
            className="button-app"
            onClick={() => {
              setStep("army");
            }}
          >
            <Icon
              icon="fluent:handshake-32-filled"
              color="#cf2323"
              width="50"
            />
            Sooltan Army
          </div>
          <div className="button-app disabled">
            <Icon icon="entypo:shop" color="#cf2323" width="45" />
            Sobat UMKM
          </div>
        </div>
      )}
      {step === "army" && <SooltanArmy setStep={setStep} />}
    </>
  );
}

const validationSchema = Yup.object().shape({
  nik: Yup.string().required("NIK dibutuhkan"),
});

const SooltanArmy = (props) => {
  const data = JSON.parse(localStorage.getItem("login"));
  const [checkingNIK, setCheckingNIK] = React.useState(null);

  const checkNIK = (nik) => {
    serviceNik.checkNIK(nik).then((res) => {
      console.log(res);
      if (res?.message?.length === 0) {
        setCheckingNIK("NIK anda tidak ditemukan");
      } else {
        setCheckingNIK(null);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        nik: data.nik,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // setOpenSultanCS(true)

        const url = `https://videocall.sooltanplasa.com/army?group_id=2&name=${data.name}&email=${data.email}&phone=${data.phone}&pesan=sooltan%20plasa%20Video%20Call`;
        // window.open(url).focus();
        window.location.replace(url);
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        isValid,
      }) => (
        <Form>
          <Row>
            <Col xs={12}>
              <div className="my-1">
                <label className="label-basic">NIK</label>
                <input
                  className="input-basic"
                  type="text"
                  placeholder="NIK"
                  onChange={handleChange}
                  value={values?.nik}
                  onBlur={(e) => {
                    handleBlur(e);
                    checkNIK(e.target.value);
                  }}
                  name="nik"
                  id="nik"
                />
                {touched.nik && !Boolean(values?.nik) && (
                  <span className="error">{errors.nik}</span>
                )}
                {Boolean(checkingNIK) && (
                  <span className="error">{checkingNIK}</span>
                )}
              </div>
            </Col>
            <Col xs={12}>
              <div className="mt-4 d-flex justify-content-center">
                <button className="btn-basic" type="submit" disabled={!isValid}>
                  Submit
                </button>
              </div>
            </Col>
            <div
              className="back-menu-r mt-3"
              onClick={() => {
                props.setStep(null);
              }}
            >
              <img src={backArrow} alt="icon" className="me-1" width={15} />
              <span>Kembali</span>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
