/** @format */

import React from "react";
import "./listProduct.style.scss";
import nextarrow from "assets/icons/nextarrow.svg";

import * as productData from "./Data";
import { truncate } from "utils/utilitys";

export default function ListProduct(props) {
  const [current, setCurrent] = React.useState("Data & Internet");
  return (
    <div className="list-product">
      <div className="tabs d-flex">
        {productData?.product.map((val, key) => (
          <div
            key={key}
            className={`mx-1 px-1 tab ${current === val.tab && "active"}`}
            onClick={() => setCurrent(val.tab)}
          >
            <span>{val.tab}</span>
          </div>
        ))}
      </div>
      <div className="products d-flex flex-wrap">
        {productData?.product
          .filter((val) => val.tab === current)[0]
          .products.map((val, key) => (
            <div className="product" key={key}>
              <img src={val.img} alt="img" />
              <h4 className="title">{val.name}</h4>
              <h4 className="text">{truncate(val.text, 20)}</h4>
              <div
                className="back-menu d-flex justify-content-between w-100 align-items-center"
                onClick={() => props.onClickDetail(val.code)}
              >
                <span>Lihat Detail</span>
                <img src={nextarrow} alt="icon" className="me-1" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
