import axios from "axios";
import { pathURL } from "./api";

// const pathURL = "http://10.194.177.84:3001/slp";

export const checkInquery = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const respon = await axios.post(`${pathURL}/inquiry-biller`, data);
      if (respon.data) {
        resolve(respon.data);
      }
    } catch (err) {
      const message = err.response
        ? `${err.response.data.message}`
        : "Oops, something wrong with our server, please try again later.";
      reject(message);
    }
  });

export const payment = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const respon = await axios.post(`${pathURL}/payment`, data);
      if (respon.data) {
        resolve(respon.data);
      }
    } catch (err) {
      const message = err.response
        ? `${err.response.data.message}`
        : "Oops, something wrong with our server, please try again later.";
      reject(message);
    }
  });

export const checkPayment = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const respon = await axios.post(`${pathURL}/payment/check-status`, data);
      if (respon.data) {
        resolve(respon.data);
      }
    } catch (err) {
      const message = err.response
        ? `${err.response.data.message}`
        : "Oops, something wrong with our server, please try again later.";
      reject(message);
    }
  });

export const paymentbillerInvoice = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const respon = await axios.post(`${pathURL}/payment-biller-by-invoice`, data);
      if (respon.data) {
        resolve(respon.data);
      }
    } catch (err) {
      const message = err.response
        ? `${err.response.data.message}`
        : "Oops, something wrong with our server, please try again later.";
      reject(message);
    }
  });
