import React from "react";
import "./renewal.scss";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";
import sooltan from "assets/images/sooltanplasa_by_telkom.png";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import axios from "axios";

const urlPromise = "https://chatservices.infomedia.co.id:8443/slp";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

export default function RenewalContract() {
  const [data, setData] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [upload, setUpload] = React.useState(false);
  const [isUploaded, setisUploaded] = React.useState(false);

  React.useEffect(() => {
    const url_string = window.location.href;
    const url_params = new URL(url_string);
    const key = url_params.searchParams.get("sessid");

    axios.get(`${urlPromise}/get-appointment/${key}`).then((res) => {
      if (res.data.error === 0) {
        setData(res.data.message[0]);
      }
    });
    axios
      .post(`${urlPromise}/get-renewal-contract`, {
        unique_key: key,
        contract_type: "agent",
      })
      .then((res) => {
        if (res.data.error === 0) {
          setFile(res.data.payload[0]);
        }
      });
  }, []);

  const fileInputRef = React.useRef();

  const generateDocument = () => {
    // const link = document.createElement("a");
    // link.href = "/file/sideletter-latest.docx";
    // link.download = "sideletter.docx";
    // link.target = "_blank";
    // link.click();
    loadFile("/file/Draft_Amandemen_Jangka_Waktu_End_Contract_FINAL.docx", function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({
        tanggal_blast_wa: data?.userdata?.dateNow ?? "[tanggal_blast_wa]",
        agree_end_date: data?.userdata?.agree_end_date ?? "[agree_end_date]",
        agree_end_date_1_day:
          data?.userdata?.agree_end_date_1_day ?? "[agree_end_date_1_day]",
        agree_end_date_1_year:
          data?.userdata?.agree_end_date_1_year ?? "[agree_end_date_1_year]",
        no_kontrak_fix: data?.userdata?.no_kontrak_fix ?? "[no_kontrak_fix]",
        bc_name: data?.userdata?.bc_name ?? "[agree_end_date]",
        harga_bulanan: data?.userdata?.harga_bulanan ?? "[harga_bulanan]",
        last_name: data?.userdata?.last_name ?? "[last_name]",
        li_product_name: data?.userdata?.li_product_name ?? "[li_product_name]",
        li_sid: data?.userdata?.li_sid ?? "[li_sid]",
        url_download: data?.url_download,
        //new
        no_kontrakFinish: data?.userdata?.no_kontrakFinish ?? "[no_kontrakFinish]",
        namaGm: data?.userdata?.namaGm ?? "[namaGm]",
        jabatanGm: data?.userdata?.jabatanGm ?? "[jabatanGm]",
        nama_ttdExisting: data?.userdata?.nama_ttdExisting ?? "[nama_ttdExisting]",
        jabatan_ttdExisting:
          data?.userdata?.jabatan_ttdExisting ?? "[jabatan_ttdExisting]",
      });
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            },
            {});
          }
          return value;
        }
        console.log(JSON.stringify({ error: error }, replaceErrors));

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI
      saveAs(out, "Draft_Amandemen_Jangka_Waktu_End_Contract_FINAL.docx");
    });
  };

  const generateContractOld = () => {
    const link = document.createElement("a");
    link.href = `/file/${data.userdata?.nama_doc_kontrak}.pdf`;
    link.target = "_blank";
    link.click();
  };

  const handleUploadClick = async () => {
    const url_string = window.location.href;
    const url_params = new URL(url_string);
    const key = url_params.searchParams.get("sessid");

    const input = document.createElement("input");
    input.type = "file";
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file.size < 50000000) {
        const fd = new FormData();

        fd.append("folder", "on5_vplaza");
        fd.append("directory", makeid(5));
        fd.append("public", true);
        fd.append("files", file);
        await setUpload(true);

        await axios
          .post("https://prod-middleware.on5.co.id/minio/upload", fd)
          .then(async (res) => {
            if (res.data.message === "Success") {
              axios
                .post(
                  "https://chatservices.infomedia.co.id:8443/slp/create-renewal-contract",
                  {
                    contract_type: "customer",
                    unique_key: key,
                    from: data.userdata?.phone,
                    fromName: data.userdata?.name,
                    ...res.data.data[0],
                  }
                )
                .then(async (res) => {
                  if (res) {
                    Swal.fire({
                      title: "Dokumen Berhasil di Upload",
                      // text: "Link video call akan dikirim ke nomor whatsapp anda",
                      icon: "success",
                      timer: 10000,
                    });
                  }
                  setisUploaded(true);
                  await setUpload(false);
                })
                .catch((res) => {
                  Swal.fire({
                    title: "Dokumen gagal di Upload",
                    // text: "Link video call akan dikirim ke nomor whatsapp anda",
                    icon: "error",
                    timer: 10000,
                  });
                  setUpload(false);
                });
            }
          })
          .catch((res) => {
            Swal.fire({
              title: "Dokumen gagal di Upload",
              // text: "Link video call akan dikirim ke nomor whatsapp anda",
              icon: "error",
              timer: 10000,
            });
            setUpload(false);
          });
        await setUpload(false);
      }
    };
    input.click();
  };
  return (
    <div className="wrapper-renewal">
      <div className="container">
        <div
          style={{
            paddingTop: 40,
          }}
        >
          <div className="d-flex justify-content-center mb-2 mt-5 ">
            <img src={sooltan} alt="soltan plas" width="200px" />
          </div>
          <div className="d-flex justify-content-center mb-3">
            <div className="box">
              <span>
                Terima kasih atas kesediaannya untuk memperpanjang kontrak
                berlangganan secara Digital.
              </span>
              <div className="row my-3">
                <div className="col-8">
                  Harap download untuk mendapatkan Kontrak berlangganan yang
                  masih aktif terlampir.
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={generateContractOld}
                  >
                    <Icon icon="fa-solid:download" color="white" width="15" />
                    <span style={{ marginLeft: 10 }}>Download Kontrak</span>
                  </button>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-8">
                  Harap download dan review dokumen amandementerlampir. Mohon
                  dilengkapi nama dan jabatan serta menandatangani sebagai tanda
                  persetujuan atas pelaksanaan perpanjangan kontrak ini.
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={generateDocument}
                  >
                    <Icon icon="fa-solid:download" color="white" width="15" />
                    <span style={{ marginLeft: 10 }}>Download Amandemen</span>
                  </button>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-8">
                  Harap upload dokumen softcopy amandemen jika sudah
                  ditandatangani.
                </div>
                <div className="col-4">
                  <input
                    onChange={(e) => console.log(e)}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                    style={{ display: "none" }}
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleUploadClick}
                    disabled={isUploaded}
                    // disabled={true}
                  >
                    <Icon icon="fa-solid:upload" color="white" width="15" />
                    <span style={{ marginLeft: 10 }}>
                      {upload ? "Uploading document..." : "Upload Amandemen"}
                    </span>
                  </button>
                </div>
              </div>
              {file && (
                <div className="row my-3">
                  <div className="col-12">
                    <b>
                      Terlampir dokumen amandemen perpanjangan kontrak yang
                      sudah ditandatangani kedua belah pihak.
                    </b>
                  </div>
                  <div className="col-4">
                    <input
                      onChange={(e) => console.log(e)}
                      multiple={false}
                      ref={fileInputRef}
                      type="file"
                      hidden
                      style={{ display: "none" }}
                    />
                    <a href={file.url} target="_blank" rel="noreferrer">
                      <button type="button" className="btn btn-danger">
                        <Icon
                          icon="fa-solid:download"
                          color="white"
                          width="15"
                        />
                        <span style={{ marginLeft: 10 }}>Download</span>
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
