import React from "react";
import backArrow from "assets/icons/backarrow.svg";
import './button.style.scss'

export default function Back(props) {
  return (
    <div
      className="back-menu-r mt-3"
      onClick={() => {
        props.back();
      }}
    >
      <img src={backArrow} alt="icon" className="me-1" width={15} />
      <span>Kembali</span>
    </div>
  );
}
