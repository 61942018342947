import React, { useEffect } from "react";
import Routes from "app/Routers";

//redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Imports: Redux Persist Persister
import persistedStore from "store/store"; // Import redux store



export default function Index() {
  const { store, persistor } = persistedStore();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}
