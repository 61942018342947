import axios from "axios";
import { pathURL } from "./api";

export const checkNIK = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const respon = await axios.get(`${pathURL}/nik/${data}`);
      if (respon.data) {
        resolve(respon.data);
      }
    } catch (err) {
      const message = err.response
        ? `${err.response.data.message}`
        : "Oops, something wrong with our server, please try again later.";
      reject(message);
    }
  });
