import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function PublicRoute({ component: Component, auth, ...rest }) {
  return (
    <Route
      {...rest}
      exact={rest.isExact}
      render={(props) =>
        auth.isLoging ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
