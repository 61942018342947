/** @format */

import React from "react";
import ReactDOM from "react-dom";
import App from "app/index";
import reportWebVitals from "./reportWebVitals";
import "assets/styles/index.scss";
import ClearCache from "react-clear-cache";
import Cache from "./Cache";
// import ReactGA from "react-ga4";
// const trackingId = "G-5E84H3PS0G";
// const login = localStorage.getItem("login");
// ReactGA.initialize(trackingId);
// ReactGA.set({
//   user: login ? JSON.parse(login)?.email : "not login user",
// });

ReactDOM.render(
  <React.StrictMode>
    <ClearCache>
      {({ isLatestVersion, emptyCacheStorage }) =>
        !isLatestVersion ? <Cache emptyCacheStorage={emptyCacheStorage} /> : <App />
      }
    </ClearCache>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
