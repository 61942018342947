import React from "react";
import { checkInquery, payment } from "service/payment";
import { Row, Col, Button } from "react-bootstrap";
import PaymentForm from "components/molecules/Form/PaymentForm";
import Swal from "sweetalert2";
import numberSuperator from "utils/numbersperator";
import moment from "moment";

export default function Bayar() {
  const [loading, setLoading] = React.useState(false);
  const [inquery, setEnquery] = React.useState(null);
  const data = JSON.parse(localStorage.getItem("login"));

  const inquiry = async (value) => {
    await setLoading(true);
    checkInquery(value)
      .then((res) => {
        if (res?.resultCode === "00") {
          let totalTagihan = 0;
          const tagihan = Object.keys(res.customerData)
            .filter((val) => val.includes("billInfo"))
            .map((val) => {
              const value = {
                ...res.customerData[val],
              };
              totalTagihan += parseInt(value.nilaiTagihan);
              return value;
            });
          setEnquery({
            ...res,
            tagihan: tagihan,
            totalTagihan,
            no_pelanggan: value.customerNumber,
          });
        } else {
          Swal.fire({
            title: "Pembayaran gagal dibuat",
            text: res.resultDesc,
            icon: "warning",
            timer: 10000,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Pembayaran gagal dibuat",
          text: "Silakan coba beberapa saat lagi",
          icon: "warning",
          timer: 10000,
        });
        setLoading(false);
      });
  };

  const paymentAction = () => {
    setLoading(true);
    const payload = {
      email: data?.email,
      name: inquery?.customerData?.customerName,
      phone: data?.phone,
      no_pelanggan: inquery.no_pelanggan,
      tagihan:
        parseInt(inquery?.totalTagihan) +
        parseInt(inquery?.feeAmount),
    };
    payment(payload)
      .then((res) => {
        setLoading(false);
        if (res?.landing_page) {
          window.location.href = res.landing_page;
        } else {
          Swal.fire({
            title: "Pembayaran gagal dibuat",
            // text: "Link video call akan dikirim ke nomor whatsapp anda",
            icon: "error",
            timer: 10000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: "Pembayaran gagal dibuat",
          // text: "Link video call akan dikirim ke nomor whatsapp anda",
          icon: "error",
          timer: 10000,
        });
      });
  };

  const noTagihan = (string) => {
    try {
      const year = string?.slice(0, 1);
      const month = string?.slice(1, 3);
      return `${moment()
        .month(month)
        .subtract(1, "M")
        .format("MMMM")} 202${year}`;
    } catch (error) {
      return 0;
    }
  };

  return (
    <div className="mt-2">
      {!inquery ? (
        <PaymentForm
          onNext={(e) => {
            inquiry({
              customerNumber: e,
            });
          }}
          loading={loading}
        />
      ) : (
        <div className="bill">
          <Row>
            <Col xs={4}>No. Pelanggan</Col>
            <Col xs={8}>{inquery?.no_pelanggan}</Col>
            <Col xs={4}>Name</Col>
            <Col xs={8}>{inquery?.customerData?.customerName}</Col>
            <Col xs={4}>Email</Col>
            <Col xs={8}>{data?.email}</Col>
            <Col xs={4}>Phone</Col>
            <Col xs={8}>{data?.phone}</Col>
            <Col xs={12} className="mt-3">
              <b>Tagihan</b>
            </Col>
            {inquery?.tagihan?.map((val) => (
              <>
                <Col xs={4}>{noTagihan(val?.nomorReferensi)}</Col>
                <Col xs={8}>
                  Rp. {numberSuperator(parseInt(val.nilaiTagihan))}
                </Col>
              </>
            ))}
            <Col xs={12} className="mt-3"></Col>
            <Col xs={4}>Total Tagihan</Col>
            <Col xs={8}>
              Rp. {numberSuperator(parseInt(inquery.totalTagihan))}
            </Col>
            <Col xs={4}>Admin</Col>
            <Col xs={8}>
              Rp. {numberSuperator(parseInt(inquery.feeAmount))}
            </Col>
            <Col xs={4}>Total Bayar</Col>
            <Col xs={8}>
              Rp. {numberSuperator(parseInt(inquery.feeAmount)+parseInt(inquery.totalTagihan))}
            </Col>
            <Col xs={12} className="mt-5">
              <Button
                variant="danger"
                style={{ width: "100%" }}
                onClick={paymentAction}
                disabled={loading}
              >
                {loading ? "Please wait.." : "Bayar"}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
