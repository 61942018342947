import React from "react";
import "./invoice.style.scss";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import metaplas from "assets/logo192.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { Icon } from "@iconify/react";
import numberSuperator from "utils/numbersperator";

import { paymentbillerInvoice } from "service/payment";

export default function Invoice() {
  const [data, setData] = React.useState(null);
  const url_string = window.location.href;
  const url_params = new URL(url_string);
  const invoice = url_params.searchParams.get("invoice");

  React.useEffect(() => {
    if (invoice) {
      paymentbillerInvoice({
        invoice,
      })
        .then((res) => {
          if (res.data) {
            const data = res.data;
            let totalTagihan = 0;
            const tagihan = Object.keys(data.customerData)
              .filter((val) => val.includes("billInfo"))
              .map((val) => {
                const value = {
                  ...data.customerData[val],
                };
                totalTagihan += parseInt(value.nilaiTagihan);
                return value;
              });
            setData({
              ...data,
              tagihan: tagihan,
              totalTagihan,
            });
          } else {
            setData("failed");
          }
        })
        .catch((err) => {
          setData("failed");
        });
    }
  }, []);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    console.log(input);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 290);
      // pdf.output('dataurlnewwindow');
      pdf.save(`invoice-${invoice}.pdf`);
    });
  };

  const noTagihan = (string) => {
    try {
      const year = string?.slice(0, 1);
      const month = string?.slice(1, 3);
      return `${moment()
        .month(month)
        .subtract(1, "M")
        .format("MMMM")} 202${year}`;
    } catch (error) {
      return 0;
    }
  };
  return (
    <main id="invoice">
      <div
        className="d-flex justify-content-end p-2"
        style={{ backgroundColor: "#ad1d13", width: "100%", maxWidth: 360 }}
      >
        <Button variant="danger" onClick={printDocument}>
          <Icon icon="fluent:print-20-filled" color="white" />
        </Button>
      </div>
      <div className="wrapper" id="divToPrint">
        <div className="container-invoice">
          <Row>
            <Col xs="12">
              <img
                src={metaplas}
                alt="logo-metaplasa"
                width="200px"
                className="logo"
              />
              <span
                style={{ fontWeight: "bold", marginLeft: 20, fontSize: 16 }}
              >
                Invoice
              </span>
            </Col>
            <Col xs="12">
              <span className="text-break">No. Transaction</span>
            </Col>
            <Col
              xs="12"
              className="text-break"
              style={{
                marginBottom: 20,
              }}
            >
              <span
                className="text-break"
                style={{
                  fontWeight: "bold",
                  marginBottom: 11,
                  fontSize: 20,
                }}
              >
                {invoice}
              </span>
            </Col>

            {data && data !== "failed" ? (
              <>
                <Col xs="6">
                  <span className="text-break">No Pelanggan</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {data?.customerData?.customerId?.replace("0", "")}
                  </span>
                </Col>
                <Col xs="6">
                  <span className="text-break">Nama</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {data?.customerData?.customerName}
                  </span>
                </Col>
                <Col xs="6">
                  <span className="text-break">NPWP</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {data?.customerData?.npwp}
                  </span>
                </Col>
                <Col xs={12} className="mt-3">
                  <b>Tagihan</b>
                </Col>
                {data?.tagihan?.map((val) => (
                  <>
                    <Col xs={6}>{noTagihan(val?.nomorReferensi)}</Col>
                    <Col xs={6}>
                      Rp. {numberSuperator(parseInt(val.nilaiTagihan))}
                    </Col>
                  </>
                ))}
                <Col xs={12} className="mt-3"></Col>
                <Col xs="6">
                  <span className="text-break">Jumlah Tagihan</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Rp. {numberSuperator(data?.totalTagihan)}
                  </span>
                </Col>
                <Col xs="6">
                  <span className="text-break">Biaya Admin</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Rp.{numberSuperator(data?.feeAmount)}
                  </span>
                </Col>
                <Col xs="6">
                  <span className="text-break">Total Bayar</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Rp.
                    {numberSuperator(
                      parseInt(data?.totalTagihan) + parseInt(data?.feeAmount)
                    )}
                  </span>
                </Col>
                <Col xs="6">
                  <span className="text-break">Tanggal Pembayaran</span>
                </Col>
                <Col xs="6">
                  <span
                    className="text-break"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {data.timeStamp.slice(0, 19)}
                  </span>
                </Col>
              </>
            ) : data === "failed" ? (
              <Col xs="12">
                <span
                  className="text-break"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Invoice not found / payment failed
                </span>
              </Col>
            ) : (
              <Col className="d-flex justify-content-center">
                <Spinner animation="border" role="status" />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </main>
  );
}
