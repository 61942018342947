import Home from "pages/Home";
import NotFound from "pages/404";
import Welcome from "pages/Welcome";
import RenewalContract from "pages/RenewalContract";
import Success from "pages/Success";
import Failed from "pages/Failed";
import Invoice from "pages/invoice";

/*
    list router 
    1. Path for url weh
    2. Component form pages file
    4. isExact: react router dom
    3. Type route for validate is user login or not "Private" || "Public" || "All"

*/

const listRoutes = [
  {
    path: "/",
    component: Home,
    isExact: true,
    type: "All"
  },
  {
    path: "/welcome",
    component: Welcome,
    isExact: true,
    type: "All"
  },
  {
    path: "/renewal-contract",
    component: RenewalContract,
    isExact: true,
    type: "All"
  },
  // {
  //   path: "/payment-success",
  //   component: Success,
  //   isExact: true,
  //   type: "All"
  // },
  {
    path: "/payment-failed",
    component: Failed,
    isExact: true,
    type: "All"
  },
  {
    path: "/payment-success",
    component: Invoice,
    isExact: true,
    type: "All"
  },
  //add roout in
  {
    path: "/*",
    component: NotFound,
    isExact: false,
    type: "All"
  },
];

export default listRoutes;
