import React from "react";
import "./boxbig.style.scss";
import backArrow from "assets/icons/backarrow.svg";

export default function BigBox(props) {
  const { postion, title, icon, children, disibleBack, disibleTitle } = props;

  return (
    <div className="boxBig" style={postion}>
      <div className="header d-flex justify-content-between p-2 align-items-center">
        {!disibleBack && (
          <div className="back-menu"
          onClick={props?.back}
          >
            <img src={backArrow} alt="icon" className="me-1" width={15} />
            <span>Kembali</span>
          </div>
        )}
        {!disibleTitle && (
          <>
            <div className="icon">
              <img src={icon} alt="icon" />
            </div>
            <div className="title">
              <span>{title}</span>
            </div>
          </>
        )}

        <div className="back-menu" onClick={props?.backMenu}>
          Keluar ke menu utama
        </div>
      </div>
      {children}
    </div>
  );
}
