import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Back from "components/atomns/Button/Back";
import "./payment.style.scss";

import BayarMethod from "./BayarMethod";
import CheckMethod from "./CheckMethod";

export default function Payment() {
  const [typeAction, setTypeAction] = React.useState(null); // cek, bayar
  return (
    <div className="payment">
      {!typeAction && (
        <div className="d-flex flex-column justify-content-around align-items-center mt-4">
          <Button
            variant="danger"
            style={{ width: "100%" }}
            onClick={() => {
              setTypeAction("bayar");
            }}
          >
            Bayar Tagihan
          </Button>
          <Button
            variant="danger"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => {
              setTypeAction("cek");
            }}
          >
            Cek Status Pembayaran
          </Button>
        </div>
      )}

      {typeAction === "bayar" && <BayarMethod />}
      {typeAction === "cek" && <CheckMethod />}
      {typeAction && (
        <Back
          back={() => {
            setTypeAction(null);
          }}
        />
      )}
    </div>
  );
}
