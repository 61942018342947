import React from 'react'
import './boxWhite.style.scss'

export default function Index({children}) {
  return (
    <div className='boxWhite'>
        {children}
    </div>
  )
}
