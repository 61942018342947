import React from "react";

export default function Cache(props) {
  const { emptyCacheStorage, isLatestVersion } = props;
  React.useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion]);
  return <p>Loading...</p>;
}
