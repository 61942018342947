/** @format */

import "./productDetail.style.scss";
import React from "react";
import * as productData from "./Data";

export default function productDetail(props) {
  const { selected } = props;
  const { productDetai } = productData;
  return (
    <div className="product-detail d-flex">
      <div className="info">
        <div className="product">
          <img src={productDetai[selected].img} alt="img" />
          <h4 className="title">{productDetai[selected].name}</h4>
          <h4 className="text">{productDetai[selected].text}</h4>
          <a href={productDetai[selected].url} target="_blank" rel="noreferrer">
            <button className="btn-basic btn-small">
              Pelajari Lebih lanjut
            </button>
          </a>
        </div>
      </div>
      <div className="details">
        <h4 className="title mb-3">Layanan {productDetai[selected].name}</h4>
        {productDetai[selected].data.map((val, key) => (
          <div className="d-flex detail my-2" key={key}>
            <img src={val.image} alt="icon" />
            <div className="d-flex flex-column detail ms-2">
              <h4>{val.title}</h4>
              <span>{val.data}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
