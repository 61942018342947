/** @format */

import React from "react";
import "./welcomePage.style.scss";
import SignForm from "components/molecules/Form/SignForm";

export default function WelcomePage(props) {
  const [openForm, setOpenForm] = React.useState(false);
  const data = JSON.parse(localStorage.getItem("login"));

  return (
    <div className="welcome">
      {openForm ? (
        <SignForm actionLogin={props.actionLogin} />
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 style={{ color: "#fff", textAlign: "center" }}>WELCOME TO PLASA INDIBIZ</h1>
          <button
            className="btn-basic"
            onClick={() => {
              if (data) {
                props.actionLogin();
              } else {
                setOpenForm(true);
              }
            }}
          >
            Mulai Jelajahi
          </button>
        </div>
      )}
    </div>
  );
}
