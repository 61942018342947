import React from "react";
import "./infowrapper.style.scss";
import prevIcon from "assets/icons/prev.svg";

export default function InfoWrapper(props) {
  const { postion, title, icon, children } = props;
  return (
    <div className="InfoWrapper" style={postion} >
      <div className="wrapper-poiny d-flex justify-content-between align-items-center" onClick={() => props.onClick()}>
        <div className="icon">
          <img src={icon} alt="icon" />
        </div>
        <div className="text d-flex align-items-center">
          <span>{title}</span>
        </div>
        <div className="icon-next" >
          <img src={prevIcon} alt="icon" />
        </div>
      </div>
      <div className="info">{children}</div>
    </div>
  );
}
