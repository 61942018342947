/** @format */

import React from "react";
import InfoPoint from "components/molecules/InfoPoint";
import InfoWrapper from "components/molecules/InfoWrapper";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import WelcomePage from "components/Organisms/WelcomePage";
import Iframe from "components/molecules/Iframe";
import productIcon from "assets/icons/product.svg";
import videoIcon from "assets/icons/video.svg";
import paymentIcon from "assets/icons/payment.svg";
import waIcon from "assets/icons/wa.svg";
import csIcon from "assets/icons/cs.svg";

import lgBerlangganan from "assets/images/logo_berlangganan.png";
import lgInfoProduk from "assets/images/logo_info_produk.png";
import lgPembayaran from "assets/images/logo_pembayaran.png";
import lgVideoCall from "assets/images/logo_videocall.png";

import BoxWhite from "components/atomns/BoxWhite";
import BigBox from "components/molecules/BoxBig";
import Modal from "components/molecules/Modal";
import Navbar from "components/molecules/Navbar";

//product page
import ListProduct from "components/Organisms/Product/ListProduct";
import ProductDetail from "components/Organisms/Product/productDetail";
import Payment from "components/Organisms/Payment";
import LayananKonsultasi from "components/Organisms/LayananKonsultasi";
import ReactGA from "react-ga4";
import { decrypt, encrypt } from "utils/utilitys";

const eventTrack = (category, action, label) => {
  console.log("GA event:", category, ":", action, ":", label);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export default function StartPage() {
  const [isLogin, setIslogin] = React.useState(false);
  const [openSultanCS, setOpenSultanCS] = React.useState(false);
  const [openAktivasi, setOpenAktivasi] = React.useState(false);
  const [openDanaKemitraan, setOpenDanaKemitraan] = React.useState(false);

  const [openBoxBig, setOpenBoxBig] = React.useState(false);
  const [prodcutDetail, setProductDetail] = React.useState(null);

  const [openPayment, setOpenPaymet] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState(null);

  const [layanankonsultesi, setlayanankonsultesi] = React.useState(false);

  return (
    <>
      <Navbar
        isLogin={isLogin}
        onClickSignOut={() => {
          setIslogin(false);
          localStorage.clear();
        }}
      />
      <div>
        {!isLogin && <WelcomePage actionLogin={() => setIslogin(true)} />}
        {openAktivasi && (
          <Iframe
            url="https://sobatbisnistelkom.id/plasaindibiz/"
            title="Mulai Berlangganan"
            backMenu={() => setOpenAktivasi(false)}
          />
        )}
        {openDanaKemitraan && (
          // <Iframe
          //   url="https://sobatbisnistelkom.id/sooltanplasa_reg/"
          //   title="Dana Kemitraan"
          //   backMenu={() => setOpenDanaKemitraan(false)}
          // />
          <Modal
            icon={paymentIcon}
            title="Dana Kemitraan"
            backMenu={() => {
              setOpenDanaKemitraan(false);
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#fff",
                fontSize: "20px",
              }}
            >
              Coming Soon
            </div>
          </Modal>
        )}
        {openPayment && (
          <Modal
            icon={paymentIcon}
            title="Payment"
            backMenu={() => {
              setOpenPaymet(false);
              setSelectedPayment(false);
            }}
          >
            <Payment onSelect={(e) => setSelectedPayment(e)} selectedPayment={selectedPayment} />
          </Modal>
        )}
        {layanankonsultesi && (
          <Modal
            icon={productIcon}
            title="Layanan Konsultasi"
            backMenu={() => {
              setlayanankonsultesi(false);
            }}
          >
            <LayananKonsultasi />
          </Modal>
        )}

        {/* Zooom in zoom out */}
        {/* <TransformWrapper
          // maxScale={5}
          minScale={1.2}
          maxScale={1.2}
          initialScale={1.2}
          centerOnInit
          wheel={{
            disabled: true,
          }}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => ( */}
        {/* <TransformComponent> */}
        <div className="main-bg">
          {/* Buttton Awal  */}

          <>
            {/* <InfoPoint
                      postion={{
                        top: 270,
                        left: 60,
                        postion: "right",
                      }}
                      title="Dana Kemitraan"
                      icon={productIcon}
                      onClick={() => {
                        // setOpenSultanCS(true)
                        eventTrack("Dana Kemitraan","Dana Kemitraan", "Button")
                        const data = JSON.parse(localStorage.getItem("login"));

                        const url = `https://videocall.sooltanplasa.com/?name=${data.name}&email=${data.email}&phone=${data.phone}&pesan=sooltan%20plasa%20Video%20Call`;
                        window.open(url, "_blank").focus();
                      }}
                      // onClick={() => {

                      //   setOpenDanaKemitraan(true);
                      // }}
                    /> */}
            {/* <InfoPoint
                      postion={{
                        top: 500,
                        right: 420,
                        postion: "right",
                      }}
                      title="Info Product"
                      icon={productIcon}
                      onClick={() => setOpenBoxBig(true)}
                    /> */}
            <InfoPoint
              disabled={openBoxBig}
              postion={{
                bottom: "62vh",
                left: "20vw",
                postion: "right",
              }}
              title="Info Produk"
              id="infoProduk"
              icon={lgInfoProduk}
              onClick={() => {
                eventTrack("Info Produk", "Info Produk", "Button");
                setOpenBoxBig(true);
              }}
            />
            {/* <InfoPoint
                      postion={{
                        bottom: 120,
                        left: 290,
                      }}
                      title="Layanan Konsultasi"
                      icon={productIcon}
                      onClick={() => setlayanankonsultesi(true)}
                    /> */}
            <InfoPoint
              disabled={openBoxBig}
              id="mulaiBerlangganan"
              postion={{
                bottom: "25vh",
                left: "1vw",
              }}
              title="Mulai Berlangganan"
              icon={lgBerlangganan}
              onClick={() => {
                eventTrack("Mulai Berlangganan", "Mulai Berlangganan", "Button");

                setOpenAktivasi(true);
              }}
            />
            {/* <InfoPoint
                      postion={{
                        bottom: 150,
                        right: 80,
                      }}
                      title="Mulai Berlangganan"
                      icon={productIcon}
                      onClick={() => setOpenAktivasi(true)}
                    /> */}
            {!openSultanCS ? (
              <InfoPoint
                disabled={openBoxBig}
                id="videoCall"
                postion={{
                  bottom: "59vh",
                  right: "10vw",
                  // postion: "left",
                }}
                title="Video Call indibiz assistance"
                icon={lgVideoCall}
                onClick={() => {
                  // setOpenSultanCS(true)
                  eventTrack("Video Call indibiz assistance", "Video Call indibiz assistance", "Button");

                  const data = JSON.parse(localStorage.getItem("login"));
                  const payload = {
                    group_id: 1,
                    account: "plasaindibiz",
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    pesan: "Plasaindibiz Video Call",
                  };

                  const url = `${process.env.REACT_APP_URL_PORTAL_VIDCALL}/?session=${btoa(
                    encrypt(JSON.stringify(payload))
                  )}`;
                  // console.log(decrypt(encrypt(JSON.stringify(payload))));
                  // console.log(url);
                  window.open(url, "_blank").focus();
                }}
              />
            ) : (
              <InfoWrapper
                postion={{
                  top: 330,
                  left: 280,
                }}
                title="Sooltan Care"
                icon={productIcon}
                onClick={() => setOpenSultanCS(false)}
              >
                <div className="d-flex justify-content-around">
                  <div
                    href=""
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const data = JSON.parse(localStorage.getItem("login"));

                      const url = `https://videocall.sooltanplasa.com/?name=${data.name}&email=${data.email}&phone=${data.phone}&pesan=sooltan%20plasa%20Video%20Call`;
                      console.log(url);
                      window.open(url, "_blank").focus();
                    }}
                  >
                    <BoxWhite on>
                      <img src={videoIcon} alt="iconm" />
                    </BoxWhite>
                  </div>
                  <a href="https://wa.me/628112240250" target="_blank">
                    <BoxWhite>
                      <img src={waIcon} alt="iconm" />
                    </BoxWhite>
                  </a>
                </div>
              </InfoWrapper>
            )}
            {/* <InfoPoint
              disabled={openBoxBig}
              postion={{
                bottom: "10vh",
                right: "15vw",
              }}
              id="pembayaran"
              title="Pembayaran"
              // commingSoon
              icon={lgPembayaran}
              onClick={() => {
                eventTrack("Pembayaran", "Pembayaran", "Button");
                window.fbq("trackCustom", "Pembayaran", { nama: "Deri Komara" });
                setOpenPaymet(true);
              }}
            /> */}
          </>

          {openBoxBig && (
            <BigBox
              icon={productIcon}
              title="Informasi Produk"
              postion={{ top: 258, right: 320, postion: "right" }}
              backMenu={() => {
                setOpenBoxBig(false);
                setProductDetail(null);
              }}
              back={() => setProductDetail(null)}
              disibleBack={!prodcutDetail}
              disibleTitle={prodcutDetail}
            >
              {prodcutDetail ? (
                <ProductDetail selected={prodcutDetail} />
              ) : (
                <ListProduct onClickDetail={(e) => setProductDetail(e)} />
              )}
            </BigBox>
          )}
        </div>
        {/* </TransformComponent> */}
        {/* )}
        </TransformWrapper> */}
      </div>
    </>
  );
}
