import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch,  } from "react-router-dom";

import listRoutes from "./listRoutes";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

export default class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return (
      <>
        {!this.state.hasError ? (
          <Router basename="/sooltanplasa" >
            <Switch>
              {listRoutes.map((val) => {
                if (val.type === "Private") {
                  return <PrivateRoute {...val} key={val.path} />;
                } else if (val.type === "Public") {
                  return <PublicRoute {...val} key={val.path} />;
                } else {
                  return (
                    <Route path={val.path} key={val.path} exact={val.isExact}>
                      <val.component />
                    </Route>
                  );
                }
              })}
            </Switch>
          </Router>
        ) : (
          <h1>Error</h1>
        )}
      </>
    );
  }
}
