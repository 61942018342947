import React from "react";
import InvoiceForm from "components/molecules/Form/InvoiceForm";
import { checkPayment } from "service/payment";

export default function CheckMethod() {
  const [loading, setLoading] = React.useState(false);

  const check = async (value) => {
    await setLoading(true);
    checkPayment({
      invoice: value,
    })
      .then((res) => {
        console.log(res);
        if (res?.result_code === "00") {
          window.location.href = `/payment-success?${
            res.amount ? `&amount=${res.amount}` : ""
          }${res.invoice ? `&invoice=${res.invoice}` : ""}${
            res.result_desc ? `&desc=${res.result_desc}` : ""
          }`;
        } else {
          window.location.href = `/payment-failed?${
            res.amount ? `&amount=${res.amount}` : ""
          }${res.invoice ? `&invoice=${res.invoice}` : ""}${
            res.result_desc ? `&desc=${res.result_desc}` : ""
          }`;
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="mt-2">
      <InvoiceForm
        onNext={(e) => {
          check(e);
        }}
        loading={loading}
      />
    </div>
  );
}
