import React, { useEffect } from "react";
import "./failed.style.scss";
import { Button, Row, Col, Container } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import axios from "axios";
export default function Failed() {
  const url_string = window.location.href;
  const url_params = new URL(url_string);
  const invoice = url_params.searchParams.get("invoice");
  const amount = url_params.searchParams.get("amount");
  const desc = url_params.searchParams.get("desc");
  return (
    <div className="success">
      <Icon icon="akar-icons:circle-x-fill" color="#d10000" width="60" />
      <p>Payment Failed</p>
      {invoice || amount || desc ? (
        <div
          style={{
            width: "400px",
            marginBottom: "20px",
            padding: "20px",
            border: "1px solid #cecece",
            borderRadius: "8px",
          }}
        >
          <Row>
            {invoice && (
              <>
                <Col xs={5}>No transaction</Col>
                <Col xs={7}>
                  <b>{invoice}</b>
                </Col>
              </>
            )}
            {amount && (
              <>
                <Col xs={5}>Jumlah</Col>
                <Col xs={7}>
                  <b>Rp. {amount}</b>
                </Col>
              </>
            )}
            {desc && (
              <>
                <Col xs={5}>Keterangan</Col>
                <Col xs={7}>
                  <b>{desc}</b>
                </Col>
              </>
            )}
          </Row>
        </div>
      ) : (
        <Row>
          <Col>Payment tidak di temukan</Col>
        </Row>
      )}

      <Link to="/">
        <Button variant="light">Kembali</Button>
      </Link>
    </div>
  );
}
