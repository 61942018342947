/** @format */

import astinet_lite from "assets/product/astinet_lite.png";
import astinet from "assets/product/astinet.png";
import sooltannet from "assets/product/sooltannet.png";
import wista from "assets/product/wista.png";
import fixed_voice from "assets/product/fixed_voice.png";
import agree from "assets/product/agree.png";
import oca from "assets/product/oca.png";
import pijar from "assets/product/pijar.png";
import sooltankasir from "assets/product/sooltankasir.png";
import umeetme from "assets/product/umeetme.png";
import sooltanpay from "assets/product/sooltanpay.png";
import adwiz from "assets/product/adwiz.png";
import padiumkm from "assets/product/padiumkm.png";

import dedicated from "assets/product/astinet_lite/layanan-dedicated.png";
import IP from "assets/product/astinet_lite/icon-Layanan--IP.png";
import SLG from "assets/product/astinet_lite/icon-Layanan-SLG.png";
import new1 from "assets/product/astinet_lite/icon-Layanan-new.png";
import coverage from "assets/product/astinet/layanan-coverage-luas.png";
import quality from "assets/product/astinet/layanan-quality-service.png";
import monthly from "assets/product/astinet/layanan-monthly-based.png";
import high from "assets/product/sooltanNet/layanan-high-speed.png";
import wms from "assets/product/sooltanNet/layanan-wms.png";
import telepon from "assets/product/sooltanNet/layanan-telepon.png";
import useetv from "assets/product/sooltanNet/layanan-useetv.png";
import agreemarket from "assets/product/agreemarket.png";
import agreemodal from "assets/product/agreemodal.png";
import agreepartner from "assets/product/agreepartner.png";
import DOOH from "assets/product/DOOH.png";
import MobileAds from "assets/product/Mobile-ads.png";
import Onloneads from "assets/product/Onlone-ads.png";
import imgIndibiz from "assets/images/Indibiz_Logo_Primer.jpg";

import imgip from "assets/product/ip.png";
import layananDedicated from "assets/product/layanan_dedicated.png";
import imgslg from "assets/product/slg.png";
import valueAdded from "assets/product/value_added.png";

export const productDetai = {
  astinet_regular: {
    name: "Layanan Astinet Reguler",
    img: astinet_lite,
    code: "astinet_regular",
    text: "Layanan akses internet premium dengan koneksi cepat dan stabil upstream:downstream 1:1",
    url: "https://myindibiz.co.id/produk/astinet",
    // url: "",
    data: [
      {
        image: layananDedicated,
        title: "Layanan Dedicated",
        data: "Layanan akses internet premium dedicated 1:1",
      },
      {
        image: imgip,
        title: "IP",
        data: "Diberikan IP static & 5 IP Public",
      },
      // {
      //   image: new1,
      //   title: "Segmentasi Produk",
      //   data: "Mendukung dengan segmen Small Mediium Entreprise",
      // },
      {
        image: imgslg,
        title: "SLG",
        data: "Hadir dengan service Level Guarante 99%",
      },
    ],
  },
  astinet_lite: {
    name: "ASTINet Lite",
    img: astinet_lite,
    code: "astinet_lite",
    text: "Layanan akses internet premium dengan koneksi cepat dan stabil upstream:downstream 1:4",
    url: "https://myindibiz.co.id/produk/astinet",
    // url: "",
    data: [
      {
        image: layananDedicated,
        title: "Layanan Dedicated",
        data: "Layanan akses internet premium dedicated 1:4",
      },
      {
        image: imgip,
        title: "IP",
        data: "Diberikan IP Static & 1 IP public",
      },
      // {
      //   image: new1,
      //   title: "Segmentasi Produk",
      //   data: "Mendukung dengan segmen Small Mediium Entreprise",
      // },
      {
        image: imgslg,
        title: "SLG",
        data: "Hadir dengan service Level Guarante 96%",
      },
    ],
  },
  astinet_fit: {
    name: "ASTINet Fit",
    img: astinet,
    code: "astinet-fit",
    text: "Layanan akses internet premium dengan koneksi cepat dan stabil upstream:downstream 1:2",
    url: "https://myindibiz.co.id/produk/astinet",
    // url: "",
    data: [
      {
        image: layananDedicated,
        title: "Layanan Dedicated",
        data: "Layanan akses internet premium dedicated 1:2",
      },
      {
        image: imgip,
        title: "IP",
        data: "Diberikan IP Static & 1 IP public",
      },
      // {
      //   image: new1,
      //   title: "Segmentasi Produk",
      //   data: "Mendukung dengan segmen Small Mediium Entreprise",
      // },
      {
        image: imgslg,
        title: "SLG",
        data: "Hadir dengan service Level Guarante 96%",
      },
      // {
      //   image: dedicated,
      //   title: "Layanan Dedicated",
      //   data: "Layanan internet kerkecepatan tinggi dengan upstream dan downstream 1:1",
      // },
      // {
      //   image: coverage,
      //   title: "Jangkauan Luas",
      //   data: "Mampu menjangkau seluruh wilayah di Indonesia",
      // },
      // {
      //   image: quality,
      //   title: "Quality of Service",
      //   data: "Dengan kualitas pelayanan 1:1 hingga titik referensi",
      // },
      // {
      //   image: monthly,
      //   title: "Monthly Based",
      //   data: "Biaya tagihan tiap bulan",
      // },
    ],
  },
  sooltannet: {
    name: "sooltanNet",
    img: sooltannet,
    code: "sooltannet",
    text: "sooltanNet menawarkan solusi layanan internet yang tepat untuk bisnis lebih baik, memberikan paket internet cepat dan stabil, mencakup paket TV interaktif (UseeTV) dan telepon dengan harga terjangkau.",
    // url: "https://mysooltan.co.id/produk/sooltannet",
    data: [
      {
        image: high,
        title: "High Speed Internet",
        data: "Kembangkan bisnis Anda dengan layanan internet fiber optik super cepat yang stabil dan aman.",
      },
      {
        image: telepon,
        title: "Telepon",
        data: "Jalin relasi lebih luas dengan layanan telepon yang jernih, biaya lebih murah, dan berkualitas.",
      },
      {
        image: useetv,
        title: "Usee TV",
        data: "Temukan hiburan dan informasi seru dengan UseeTV",
      },
      {
        image: wms,
        title: "WiFi Managed Service",
        data: "Tarik pelanggan untuk berkunjung dengan layanan akses login internet.",
      },
    ],
  },
  wista: {
    name: "Wifi Station",
    img: wista,
    code: "wista",
    text: "Layanan internet wifi sesuai dengan kebutuhan pelanggan bisnis.",
    // text: "Layanan Internet wifi dedicated untuk kebutuhan bisnis dengan kecepatan mencapai 100 Mbps dan menggunakan 100% Fiber. Wifi Station cocok bagi pelaku bisnis yang berbadan usaha , berbadan hukum atau usaha Mikro yang membutuhkan layanan internet",
    url: "https://myindibiz.co.id/produk/wifi-internet",
    // url: "",
    data: [
      {
        image: layananDedicated,
        title: "Layanan Dedicated",
        data: "Layanan Internet wifi dedicated dengan Value Added as a Service sesuai kebutuhan pelanggan.",
      },
      {
        image: valueAdded,
        title: "Value added as a service",
        data: "Customized welcome page, Login, Profiling User, Dasboard pelanggan",
      },
      {
        image: imgslg,
        title: "SLG",
        data: "Hadir dengan Service Level Guarante 96%",
      },
      // {
      //   image: high,
      //   title: "Dashboard User",
      //   data: "Dashboard untuk memantau perangkat dan koneksi",
      // },
      // {
      //   image: telepon,
      //   title: "Jaringan Handal",
      //   data: "Jaringan 100% fiber optik yang handal",
      // },
      // {
      //   image: useetv,
      //   title: "Kuota Unlimited",
      //   data: "Bebas kuota pemakaian",
      // },
      // {
      //   image: high,
      //   title: "High Speed Internet",
      //   data: "Akses internet cepat dan stabil",
      // },
    ],
  },
  fixed_voice: {
    name: "Telepon Rumah",
    img: fixed_voice,
    code: "fixed_voice",
    text: "Alat komunikasi yang digunakan untuk...",
    data: [
      {
        image: high,
        title: "High Speed Internet",
        data: "Kembangkan bisnis Anda dengan layanan internet fiber optik super cepat yang stabil dan aman.",
      },
      {
        image: telepon,
        title: "Telepon",
        data: "Jalin relasi lebih luas dengan layanan telepon yang jernih, biaya lebih murah, dan berkualitas.",
      },
      {
        image: useetv,
        title: "Usee TV",
        data: "Temukan hiburan dan informasi seru dengan UseeTV",
      },
      {
        image: wms,
        title: "WiFi Managed Service",
        data: "Tarik pelanggan untuk berkunjung dengan layanan akses login internet.",
      },
    ],
  },
  agree: {
    name: "Agress",
    img: agree,
    code: "agree",
    text: "Aplikasi Digitalisasi Ekosistem Pertanian...",
    url: "https://mysooltan.co.id/produk/agree",
    data: [
      {
        image: agreemodal,
        title: "Agree Modal",
        data: "Permodalan petani dengan kontrol penggunaan",
      },
      {
        image: agreepartner,
        title: "Agree Partner",
        data: "Kemitraan petani dengan perusahaan pertanian dengan sistem monitoring.",
      },
      {
        image: agreemarket,
        title: "Agree Market",
        data: "B2B Marketplace Pertanian",
      },
    ],
  },
  pijar: {
    name: "Pijar",
    img: pijar,
    code: "pijar",
    text: "Solusi pembelajaran online yang dilengkapi dengan ...",
    url: "https://mysooltan.co.id/produk/pijar",
    data: [
      {
        image: high,
        title: "Lab Maya",
        data: "Laboratorium untuk mendukung pembelajaran siswa menggunakan alat dan bahan virtual",
      },
      {
        image: telepon,
        title: "Video Pembelajaran",
        data: "Video materi pembelajaran interaktif",
      },
      {
        image: useetv,
        title: "Ujian Sekolah Berbasis Aplikasi",
        data: "Ujian berbasis aplikasi",
      },
      {
        image: wms,
        title: "Sistem Manajemen Sekolah",
        data: "Sistem informasi terpadu untuk mendukung kegiatan operasional sekolah dan manajemen sekolah",
      },
    ],
  },
  sooltankasir: {
    name: "sooltanKasir",
    img: sooltankasir,
    code: "sooltankasir",
    text: "Aplikasi kasir yang memudahkan operasional bisnis...",
    url: "https://mysooltan.co.id/produk/sooltankasir",
    data: [
      {
        image: high,
        title: "Management Pelanggant",
        data: "Menyimpan dan mengelola data pelanggan.",
      },
      {
        image: telepon,
        title: "Management Pegawai",
        data: "Pengelolaan pegawai untuk mengatur role sebagai admin ataupun kasir.",
      },
      {
        image: useetv,
        title: "Menu Jualan",
        data: "Menampilkan katalog produk sebagai visualisasi saat transaksi dan sudah dilengkapi sistem barcode untuk memudahkan seleksi produk",
      },
      {
        image: wms,
        title: "Hitung Manual",
        data: "Penghitungan manual untuk proses transaksi",
      },
    ],
  },
  umeetme: {
    name: "UMeetMe",
    img: umeetme,
    code: "umeetme",
    text: "Solusi untuk menjembatani Anda agar tetap bisa berkom...",
    url: "https://mysooltan.co.id/produk/umeetme",
    data: [
      {
        image: high,
        title: "Kelola Peserta",
        data: "Kelola Peserta.",
      },
      {
        image: telepon,
        title: "Siaran Langsung",
        data: "Lakukan siaran langsung secara gratis dan mudah",
      },
      {
        image: useetv,
        title: "Berbagi Video Youtube",
        data: "Bagikan dan Saksisan bersama video youtube saat meeting berlangsung",
      },
    ],
  },
  sooltanpay: {
    name: "sooltanPay",
    img: sooltanpay,
    code: "sooltanpay",
    text: "Aplikasi pembayaran non-tunai dengan...",
    data: [
      {
        image: high,
        title: "High Speed Internet",
        data: "Kembangkan bisnis Anda dengan layanan internet fiber optik super cepat yang stabil dan aman.",
      },
      {
        image: telepon,
        title: "Telepon",
        data: "Jalin relasi lebih luas dengan layanan telepon yang jernih, biaya lebih murah, dan berkualitas.",
      },
      {
        image: useetv,
        title: "Usee TV",
        data: "Temukan hiburan dan informasi seru dengan UseeTV",
      },
      {
        image: wms,
        title: "WiFi Managed Service",
        data: "Tarik pelanggan untuk berkunjung dengan layanan akses login internet.",
      },
    ],
  },
  adwiz: {
    name: "AdWiz",
    img: adwiz,
    code: "adwiz",
    text: "Solusi dalam melakukan promosi usaha secara online...",
    url: "https://mysooltan.co.id/produk/digiads",
    data: [
      {
        image: MobileAds,
        title: "Mobile Ads",
        data: "Media beriklan menggunakan sms massal ke nomor pelanggan.",
      },
      {
        image: DOOH,
        title: "DOOH",
        data: "Media beriklan di ruang publik.",
      },
      {
        image: Onloneads,
        title: "Online Ads",
        data: "Temukan hiburan dan informasi seru dengan UseeTV",
      },
    ],
  },
  padiumkm: {
    name: "PaDi UMKM",
    img: padiumkm,
    code: "padiumkm",
    text: "Platfrom digital yang mengintegrasikan...",
    data: [
      {
        image: high,
        title: "High Speed Internet",
        data: "Kembangkan bisnis Anda dengan layanan internet fiber optik super cepat yang stabil dan aman.",
      },
      {
        image: telepon,
        title: "Telepon",
        data: "Jalin relasi lebih luas dengan layanan telepon yang jernih, biaya lebih murah, dan berkualitas.",
      },
      {
        image: useetv,
        title: "Usee TV",
        data: "Temukan hiburan dan informasi seru dengan UseeTV",
      },
      {
        image: wms,
        title: "WiFi Managed Service",
        data: "Tarik pelanggan untuk berkunjung dengan layanan akses login internet.",
      },
    ],
  },
  indibiz: {
    name: "Layanan indibiz",
    img: imgIndibiz,
    code: "indibiz",
    text: "Layanan akses internet  cepat dan stabil tanpa batas kuota upstream:downstream 1:1",
    url: "https://myindibiz.co.id/produk/indibiz-internet",
    data: [
      {
        image: layananDedicated,
        title: "Layanan Internet Broadband",
        data: "Layanan broadband shared Internet dengan ratio 1:1",
      },
      {
        image: imgip,
        title: "IP",
        data: "Diberikan IP Dinamis",
      },
      {
        image: imgslg,
        title: "SLG",
        data: "Hadir dengan service Level Guarante 96,5%",
      },
    ],
  },
};

export const product = [
  {
    tab: "Data & Internet",

    products: [
      {
        name: "Wifi Station",
        img: wista,
        code: "wista",
        text: "Layanan Internet wifi dedicated untuk ...",
      },
      // {
      //   name: "Telepon Rumah",
      //   img: fixed_voice,
      //   code: "fixed_voice",
      //   text: "Alat komunikasi yang digunakan untuk...",
      // },
      {
        name: "Layanan Astinet Reguler",
        img: astinet_lite,
        code: "astinet_regular",
        text: "Layanan akses internet premium dengan koneksi cepat dan stabil upstream:downstream 1:1",
      },
      {
        name: "ASTINet Fit",
        img: astinet_lite,
        code: "astinet_fit",
        text: "Layanan akses internet cepat...",
      },
      {
        name: "ASTINet Lite",
        img: astinet,
        code: "astinet_lite",
        text: "Layanan akses internet cepat...",
      },
    ],
  },
  {
    tab: "indibiz",
    products: [
      {
        name: "Layanan Indibiz",
        img: imgIndibiz,
        code: "indibiz",
        text: "Layanan akses internet  cepat dan stabil tanpa batas kuotaupstream:downstream 1:1",
      },
      // {
      //   name: "sooltanNet",
      //   img: sooltannet,
      //   code: "sooltannet",
      //   text: "sooltanNet menawarkan solusi layanan internet...",
      // },
      // {
      //   name: "Wifi Station",
      //   img: wista,
      //   code: "wista",
      //   text: "Layanan Internet wifi dedicated untuk ...",
      // },
    ],
  },
];

// {
//   tab: "Solusi",
//   products: [
//     {
//       name: "Agress",
//       img: agree,
//       code: "agree",
//       text: "Aplikasi Digitalisasi Ekosistem Pertanian...",
//     },
//     {
//       name: "Pijar",
//       img: pijar,
//       code: "pijar",
//       text: "Solusi pembelajaran online yang dilengkapi...",
//     },
//     // {
//     //   name: "sooltanKasir",
//     //   img: sooltankasir,
//     //   code: "sooltankasir",
//     //   text: "Aplikasi kasir yang memudahkan operasi...",
//     // },
//     {
//       name: "UMeetMe",
//       img: umeetme,
//       code: "umeetme",
//       text: "Solusi untuk menjembatani anda ager...",
//     },
//     // {
//     //   name: "sooltanPay",
//     //   img: sooltanpay,
//     //   code: "sooltanpay",
//     //   text: "Aplikasi pembayaran non-tunai dengan...",
//     // },
//     {
//       name: "AdWiz",
//       img: adwiz,
//       code: "adwiz",
//       text: "Solusi dalam melakukan promosi usaha secara...",
//     },
//     {
//       name: "PaDi UMKM",
//       img: padiumkm,
//       code: "padiumkm",
//       text: "Platfrom digital yang mengintegrasikan...",
//     },
//   ],
// },
