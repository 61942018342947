import React from "react";
import "./modal.style.scss";

export default function Modal(props) {
  return (
    <div className="backdrop">
      <div className="modal-costume">
        <div className="header d-flex justify-content-between p-2 align-items-center">
          <div className="icon">
            <img src={props.icon} alt="icon" />
          </div>
          <div className="title">
            <span>{props.title}</span>
          </div>
          <div className="back-menu" onClick={props?.backMenu}>
            Keluar ke menu utama
          </div>
        </div>
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
}
