/** @format */

import React from "react";
import "./navbar.style.scss";

import indibiz from "assets/images/Indibiz_Logo_Sekunder.png";
import telkom from "assets/images/telkom.png";
import logo from "assets/images/logo.svg";

export default function Navbar(props) {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="navbar">
          <div>
            <img src={indibiz} alt="soltan plasa" width="150px" />
          </div>
          <div className="d-flex align-items-center ">
            <div className="right-link">
              <a
                href="https://www.telkom.co.id/"
                target="_blank"
                rel="noreferrer"
                style={{
                  marginRight: 10,
                }}
              >
                <img src={telkom} alt="soltan plas" height="55px" />
              </a>
              <a
                // href="https://indibizplaza.com/"
                href="https://plasaindibiz.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  marginRight: 15,
                }}
              >
                <img src={indibiz} alt="soltan plas" width="100px" />
              </a>
            </div>
            <div>
              {props.isLogin && (
                <button
                  className="btn-signout"
                  onClick={() => {
                    props.onClickSignOut();
                  }}
                >
                  Sign out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
