import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./from.style.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import aktivasi from "assets/icons/aktivasi.svg";
import axios from "axios";
import backArrow from "assets/icons/backarrow.svg";
import * as serviceNik from "service/checknik";

export default function SignForm(props) {
  const [step, setStep] = React.useState("eksternal"); //null, internal, eksternal
  const [loading, setLoading] = React.useState(false);
  const [checkingNIK, setCheckingNIK] = React.useState(null);
  // const history = useHistory();

  const checkNIK = (nik) => {
    serviceNik.checkNIK(nik).then((res) => {
      console.log(res);
      if (res?.message?.length === 0) {
        setCheckingNIK("NIK anda tidak ditemukan");
      } else {
        setCheckingNIK(null);
      }
    });
  };
  const login = (value) => {
    setLoading(true);
    if (value) {
      localStorage.setItem("login", JSON.stringify(value));
      props.actionLogin();
    }
    setLoading(false);
    // if (value)
    //   axios
    //     .post("https://prod-api.on5.co.id/v1/integrasi/saveFormMySooltanDBS", {
    //       tenant_id: "on5_vplaza",
    //       ...value,
    //     })
    //     .then((res) => {
    //       localStorage.setItem("login", JSON.stringify(value));
    //       props.actionLogin();
    //       setLoading(false);
    //     })
    //     .then((val) => {
    //       setLoading(false);
    //     });
  };
  return (
    <div className="from-wrapper-sign">
      <div className="header d-flex justify-content-between p-2 align-items-center">
        <div className="icon">
          <img src={aktivasi} alt="icon" />
        </div>
        <div className="title">{step === null ? "Pilih Segmentasi" : "Isi data diri anda"}</div>
      </div>
      {step === null && (
        <div className="login-type" style={{ padding: "10px" }}>
          <div className="wording-segment">
            <p className="main-text">
              Mohon pilih status keikutsertaan Anda di Indibiz Plaza ini, dengan pilihan : <br />
            </p>
            <p className="type-text">
              1. intenal, jika anda adalah Telkom Group khususnya dari unit Divisi Business Service
            </p>
            <p className="type-text">
              2. external, jika anda adalah pelanggan ataupun calon pelanggan dari unit Divisi Business Service
            </p>
          </div>
          <div className="d-flex justify-content-around align-items-center mt-4">
            <Button
              variant="danger"
              style={{}}
              onClick={() => {
                setStep("internal");
              }}
            >
              Internal
            </Button>

            <Button
              variant="danger"
              onClick={() => {
                setStep("eksternal");
              }}
            >
              Eksternal
            </Button>
          </div>
        </div>
      )}
      {step !== null && (
        <Formik
          initialValues={{
            ...initialState,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const phone = values.phone.toString();
            if (phone[0] === "0") {
              values.phone = "62" + phone.replace("0", "");
            }
            if (phone[0] === "+") {
              values.phone = phone.replace("+", "");
            }
            login({
              ...values,
              segmentasi: step,
              nik: step === "internal" ? values?.nik : null,
            });
          }}
        >
          {({ errors, touched, values, handleChange, setFieldValue, handleBlur, isValid }) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <div className="my-1">
                    <label className="label-basic">Nama</label>
                    <input
                      className="input-basic"
                      type="text"
                      placeholder="Masukan Name"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                      name="name"
                      id="name"
                    />
                    {touched.name && errors.name && <span className="error">{errors.name}</span>}
                  </div>
                </Col>
                {/* <Col xs={12}>
                  <div className="my-1">
                    <label className="label-basic">Account</label>
                    <input
                      className="input-basic"
                      type="text"
                      placeholder="Masukan Account"
                      onChange={handleChange}
                      value={values.account}
                      onBlur={handleBlur}
                      name="account"
                      id="account"
                    />
                    {touched.accouhnt && errors.account && <span className="error">{errors.account}</span>}
                  </div>
                </Col> */}
                <Col xs={12}>
                  <div className="my-1">
                    <label className="label-basic">Email</label>
                    <input
                      className="input-basic"
                      type="text"
                      placeholder="Masukan Email"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      name="email"
                      id="email"
                    />
                    {touched.email && errors.email && <span className="error">{errors.email}</span>}
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="my-1">
                    <label className="label-basic">No. Telp 62xxx</label>
                    <input
                      className="input-basic"
                      type="text"
                      placeholder="Nomor yang terhubung dengan whatsapp"
                      onChange={handleChange}
                      value={values.phone}
                      onBlur={handleBlur}
                      name="phone"
                      id="phone"
                    />
                    {touched.phone && errors.phone && <span className="error">{errors.phone}</span>}
                  </div>
                </Col>
                {step === "internal" && (
                  <Col xs={12}>
                    <div className="my-1">
                      <label className="label-basic">NIK</label>
                      <input
                        className="input-basic"
                        type="text"
                        placeholder="NIK"
                        onChange={handleChange}
                        value={values?.nik}
                        onBlur={(e) => {
                          handleBlur(e);
                          checkNIK(e.target.value);
                        }}
                        name="nik"
                        id="nik"
                      />
                      {touched.nik && step === "internal" && !Boolean(values?.nik) && (
                        <span className="error">NIK diButuhkan</span>
                      )}
                      {Boolean(checkingNIK) && <span className="error">{checkingNIK}</span>}
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <div className="mt-4 d-flex justify-content-center">
                    <button className="btn-basic" type="submit" disabled={!isValid || loading}>
                      {!loading ? "Submit" : "Loading..."}
                    </button>
                  </div>
                </Col>
                {/* <div
                  className="back-menu"
                  onClick={() => {
                    setStep(null);
                  }}
                >
                  <img src={backArrow} alt="icon" className="me-1" width={15} />
                  <span>Kembali</span>
                </div> */}
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {/*  */}
    </div>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Nama dibutuhkan"),
  email: Yup.string().email("Email tidak valid").required("Email dibutuhkan"),
  phone: Yup.number()
    .typeError("Phone harus berupa angka")
    .min(10, "No Pelanggan minimal 10 digit")
    .required("Phone dibutuhkan"),
});
const initialState = {
  email: "",
  name: "",
  phone: "",
};
