/** @format */

import React from "react";
import "./infoPoint.style.scss";
import nextIcon from "assets/icons/next.svg";

export default function InfoPoint(props) {
  const { postion, title, icon, commingSoon, disabled, id } = props;
  console.log("postion", postion);
  return (
    <div id={id} className="InfoPoint" style={postion}>
      <div
        className="wrapper-poiny d-flex justify-content-between align-items-center"
        onClick={() => {
          if (!commingSoon && !disabled) {
            props.onClick();
          }
        }}
      >
        {/* <div className="icon"> */}
        <img src={icon} style={{ width: "40px" }} alt="icon" />
        {/* </div> */}
        <div className="text d-flex align-items-center">
          <span>{title}</span>
        </div>
        {/* {commingSoon ? (
          <div className="comming-soon">Coming soon</div>
        ) : (
          <div className="icon-next">
            <img src={nextIcon} alt="icon" />
          </div>
        )} */}
      </div>
      {/* <div
        className={`d-flex justify-content-${
          postion?.postion === "right" ? "end" : "start"
        }`}
      >
        <div className="d-flex flex-row align-items-center justify-content-center ">
          <div className="hr" />
          <div className="poin">
            <div className="sub-poin" />
          </div>
        </div>
      </div> */}
    </div>
  );
}
